import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Leave Attendance Management</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                 Leave Attendance Management refers to the system used to track and manage employee leave requests, absences, and overall attendance records. This system enables organizations to monitor the time employees are absent from work for various reasons (such as illness, vacation, or personal leave). It typically includes features like leave balance tracking, approval workflows, automated notifications, and integration with payroll systems.
                                </p>
                              
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Attendance Management</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                 Attendance Management focuses on monitoring and recording employee attendance. This system helps businesses track when employees arrive and leave work, ensuring compliance with company policies. It can also track tardiness, early departures, and absenteeism. This system is essential for maintaining operational efficiency and ensuring that employees meet the required working hours.
                                </p>
                                
                                
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Claim Management</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                 Claim Management is a system that handles the submission, approval, and reimbursement of employee claims. These claims may include travel expenses, medical reimbursements, or other business-related expenses. This system ensures that claims are processed efficiently, reviewed for accuracy, and paid in a timely manner. It can help prevent fraud and ensure transparency in reimbursement procedures.
                                </p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Staff Management</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                  Staff Management refers to the practices, policies, and tools used to manage the human resources (HR) of an organization. It includes recruitment, performance tracking, training, scheduling, and employee development. The goal is to ensure that the organization has the right people in the right positions, their performance is optimized, and they are supported throughout their careers.
                                </p>
                               
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;